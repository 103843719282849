import { createContext } from 'react'

export interface NavMenuContextType {
  isOpen: boolean

  setIsOpen: (isOpen: boolean) => void
}

const NavMenuContext = createContext<NavMenuContextType | null>(null)

export default NavMenuContext

import { useRef, useState } from 'react'

import useAnimationLoop from '../../../hooks/useAnimationLoop'
import useOnMount from '../../../hooks/useOnMount'
import useTimeout from '../../../hooks/utils/useTimeout'
import Blob from '../../../utils/blob-classes/Blob'

export function useLogoLinkBlob() {
  const pathRef = useRef<SVGPathElement>(null)
  const timeoutIdRef = useRef(-1)
  const [isHovered, setIsHovered] = useState(false)
  const [blob] = useState(
    () =>
      new Blob({
        radius: 45,
        disappearRadius: 10,
        pointCount: 10,
        pointRadiusOffset: 0.2,
        pointSpeedOffset: 0.005,
      })
  )

  const { start, stop } = useAnimationLoop((time) => {
    blob.update(time)

    pathRef.current?.setAttribute('d', blob.toSvgPathD())
  })

  function onMouseEnter() {
    setIsHovered(true)
    start()
    blob.radiusSpring.value = blob.radiusSpring.targetValue = blob.minRadius
    blob.appear()
    window.clearTimeout(timeoutIdRef.current)
  }

  function onMouseLeave() {
    setIsHovered(false)
    timeoutIdRef.current = window.setTimeout(stop, 500)
    blob.radiusSpring.targetValue = blob.maxRadius * 1.2
  }

  return { pathRef, isHovered, onMouseEnter, onMouseLeave }
}

let globalHasCollapsedFromTimeout = false
export function useIsLogoCollapsed() {
  const _setTimeout = useTimeout()
  const [isLogoCollapsed, setIsLogoCollapsed] = useState(
    globalHasCollapsedFromTimeout
  )

  useOnMount(() => {
    const delayBeforeCollapseMs = 2500
    _setTimeout(() => {
      globalHasCollapsedFromTimeout = true
      setIsLogoCollapsed(true)
    }, delayBeforeCollapseMs)
  })

  return isLogoCollapsed
}

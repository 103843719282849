export default class Time {
  #_now = Date.now()
  #_lastNow = Date.now()

  get now() {
    return this.#_now
  }

  get elapsedMs() {
    return this.#_now - this.#_lastNow
  }

  get elapsedS() {
    return this.elapsedMs / 1000
  }

  update() {
    this.#_lastNow = this.#_now
    this.#_now = Date.now()
  }

  reset() {
    this.#_now = this.#_lastNow = Date.now()
  }
}

import { GatsbyLinkProps, Link } from 'gatsby'
import React from 'react'

import {
  getSegmentLinkTrackingdData,
  LinkTrackProps,
} from '../../_functional/Segment/helpers'

type InternalLinkProps = {
  to: string
  segmentdata: LinkTrackProps
} & Omit<GatsbyLinkProps<{}>, 'ref'>

export const InternalLink = React.forwardRef<
  Omit<GatsbyLinkProps<{}>, 'ref'>,
  InternalLinkProps
>(function InternalLink({ children, segmentdata, to, ...props }, ref) {
  return (
    <Link
      {...props}
      to={to}
      ref={ref}
      onClick={() =>
        getSegmentLinkTrackingdData({
          anchor_text: segmentdata.anchor_text,
          destination: 'internal',
          position: segmentdata.position,
          url: segmentdata.url,
        })
      }
    >
      {children}
    </Link>
  )
})

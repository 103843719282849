const { cos, sin } = Math

export class BlobPoint {
  angle: number
  offset: number
  speed: number

  x = 0
  y = 0

  constructor(angle: number, offset: number, speed: number) {
    this.angle = angle
    this.offset = offset
    this.speed = speed
  }

  update(radius: number, time: number) {
    const deltaOffset = radius * this.offset * cos(time * this.speed)
    this.x = (radius + deltaOffset) * cos(this.angle)
    this.y = (radius + deltaOffset) * sin(this.angle)
  }

  static centerAsStr(point1: BlobPoint, point2: BlobPoint) {
    return `${(point2.x + point1.x) / 2} ${(point2.y + point1.y) / 2}`
  }
}

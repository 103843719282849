import classNames from 'classnames'
import React, { MutableRefObject } from 'react'

import styles from './layout-container.module.scss'

interface LayoutContainerProps {
  withMargin?: boolean
  smallMarginBottom?: boolean
  isWider?: boolean

  forwardRef?: MutableRefObject<HTMLDivElement | null>
}

export default function LayoutContainer({
  withMargin = false,
  smallMarginBottom = false,
  isWider = false,

  className,

  forwardRef,

  ...divProps
}: LayoutContainerProps & Omit<JSX.IntrinsicElements['div'], 'ref'>) {
  return (
    <div
      className={classNames(
        styles.layoutContainer,
        withMargin && styles.layoutContainerWithMargin,
        smallMarginBottom && styles.layoutContainerSmallMarginBottom,
        isWider && styles.layoutContainerIsWider,
        className
      )}
      ref={forwardRef}
      {...divProps}
    />
  )
}

export function caseInsensitiveEquals(
  a: string | undefined | null,
  b: string | undefined | null
) {
  if (typeof a !== 'string' || typeof b !== 'string') {
    return false
  }

  return a.toLowerCase() === b.toLowerCase()
}

export function padToLength(padChar: string, length: number, value: string) {
  if (padChar.length > 0) {
    while (value.length < length) {
      value = padChar + value
    }
  }

  return value
}

export const leadingZeroTenths = padToLength.bind(null, '0', 2)

export function renderTemplateString(
  templateStr: string,
  valuesObj: Record<string, any>
) {
  let renderedStr = templateStr

  Object.keys(valuesObj).forEach((key) => {
    const keyRegex = new RegExp(`\\[${key}\\]`, 'g')
    renderedStr = renderedStr.replace(keyRegex, String(valuesObj[key]))
  })

  return renderedStr
}

import { useEffect, useState } from 'react'

const matchMedia = typeof window === 'undefined' ? null : window.matchMedia

export default function useMatchesMedia(
  mediaQuery: string,
  defaultValueOnServer = false
) {
  const [isMatching, setIsMatching] = useState(defaultValueOnServer)

  useEffect(() => {
    if (!matchMedia) {
      return
    }

    const match = matchMedia(mediaQuery)

    function onChange() {
      setIsMatching(match.matches)
    }

    if (match.addEventListener) {
      match.addEventListener('change', onChange)
    } else {
      match.addListener(onChange)
    }

    onChange()

    return () => {
      if (match.removeEventListener) {
        match.removeEventListener('change', onChange)
      } else {
        match.removeListener(onChange)
      }
    }
  }, [mediaQuery])

  return isMatching
}

import classNames from 'classnames'
import React, { MutableRefObject } from 'react'

import Colors from '../../../constants/Colors'
import useIsNavMenuOpen from '../../../hooks/navMenu/useIsNavMenuOpen'
import useRootLink from '../../../hooks/routing/useRootLink'
import useIsDesktop from '../../../hooks/useIsDesktop'
import animateScroll from '../../../utils/animation/animateScroll'
import { getComplementaryColor } from '../../../utils/colorUtils'
import { getDefaultScrollingElement } from '../../../utils/scrollUtils'
import { caseInsensitiveEquals } from '../../../utils/stringUtils'
import SvgLogo from '../../_svg/SvgNewLogo'
import { InternalLink } from '../InternalLink/InternalLink'

import { useIsLogoCollapsed, useLogoLinkBlob } from './LogoLink.hooks'

import styles from './logo-link.module.scss'

interface LogoLinkProps {
  color: string | null

  className?: string

  forwardRef?: MutableRefObject<null>
}

export default function LogoLink({
  color,

  className,

  forwardRef,
}: LogoLinkProps) {
  const rootLink = useRootLink()
  const isDesktop = useIsDesktop()
  const isNavMenuOpen = useIsNavMenuOpen()

  const blobColor = color ?? Colors.Brown
  const blobLogoColor = getComplementaryColor(blobColor)
  const shouldForceDefaultLogoColor = isNavMenuOpen && !isDesktop

  const isLogoCollapsed = useIsLogoCollapsed()

  const { pathRef, isHovered, onMouseEnter, onMouseLeave } = useLogoLinkBlob()

  function onClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    if (caseInsensitiveEquals(window.location.href, event.currentTarget.href)) {
      event.preventDefault()
      animateScroll(getDefaultScrollingElement()!, 0)
    }
  }

  return (
    <InternalLink
      ref={forwardRef}
      className={classNames(styles.link, className)}
      to={rootLink}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      segmentdata={{
        anchor_text: 'Kruso Logo',
        position: 'body',
        url: rootLink,
      }}
    >
      <svg
        className={styles.blob}
        width="120"
        height="120"
        viewBox="0 0 120 120"
      >
        <path fill={blobColor} transform="translate(60 60)" ref={pathRef} />
      </svg>

      <SvgLogo
        className={styles.svg}
        color={
          isHovered ? blobLogoColor : shouldForceDefaultLogoColor ? null : color
        }
        isCollapsed={isLogoCollapsed}
      />
    </InternalLink>
  )
}

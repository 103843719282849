export default class Spring {
  stiffness: number
  dampness: number
  precision: number

  constructor(stiffness = 170, dampness = 30, precision = 0.01) {
    this.stiffness = stiffness
    this.dampness = dampness
    this.precision = precision
  }

  static fromSpring(spring: Partial<Spring> | null | undefined) {
    return new Spring(
      spring?.stiffness ?? undefined,
      spring?.dampness ?? undefined,
      spring?.precision ?? undefined
    )
  }
}

import { CaseContent } from '../@import-types/contentful/Case.types'
import Colors from '../constants/Colors'

export function getComplementaryColor(color: string | null | undefined) {
  switch (color) {
    case Colors.GreenDark:
      return Colors.GreenLight

    case Colors.GreenLight:
      return Colors.GreenDark

    case Colors.Orange:
      return Colors.White

    case Colors.Yellow:
      return Colors.White

    default: {
      const isDark = color && isDarkHexColor(color)
      return isDark ? Colors.White : Colors.Brown
    }
  }
}

export function getAlternativeComplementaryColor(
  color: string | null | undefined
) {
  switch (color) {
    case Colors.GreenDark:
      return Colors.Yellow

    default:
      return getComplementaryColor(color)
  }
}

export function getColorByName(colorName: string | null | undefined) {
  const normalizedColorName = String(colorName)
    .toLowerCase()
    .replace(/[ -]+/g, '')

  switch (normalizedColorName) {
    case 'greendark':
      return Colors.GreenDark

    case 'greenlight':
      return Colors.GreenLight

    case 'orange':
      return Colors.Orange

    case 'red':
      return Colors.RedDark

    case 'sand':
      return Colors.Sand

    case 'yellow':
      return Colors.Yellow

    default:
      return Colors.Brown
  }
}

export function getCaseColor(caseContent: CaseContent) {
  return caseContent.caseColor ?? caseContent.client?.color
}

export function isDarkColor(r: number, g: number, b: number) {
  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))
  return hsp <= 127.5
}

export function isDarkHexColor(hex: string) {
  // https://gist.github.com/jed/983661
  const rgbFromHex = +(
    // @ts-ignore
    ('0x' + hex.slice(1).replace(hex.length < 5 && /./g, '$&$&'))
  )

  const r = rgbFromHex >> 16
  const g = (rgbFromHex >> 8) & 255
  const b = rgbFromHex & 255

  return isDarkColor(r, g, b)
}

import animateScroll from './animation/animateScroll'

type ScrollingElement = HTMLElement | undefined

const __SERVER__ = typeof document === 'undefined'

const defaultScrollingElement: ScrollingElement | undefined = !__SERVER__
  ? (document.scrollingElement as HTMLElement) || document.documentElement
  : undefined

let disableCount: number = 0

export function getDefaultScrollingElement() {
  return defaultScrollingElement
}

export function disablePageScroll(
  element: ScrollingElement = defaultScrollingElement
) {
  if (__SERVER__ || !element) {
    return
  }

  disableCount += 1

  if (element.style.position === 'fixed') {
    return
  }

  const currentScroll: number = element?.scrollTop

  element.style.position = 'fixed'
  element.style.top = `-${currentScroll}px`
  element.style.width = '100%'
}

export function enablePageScroll(
  element: ScrollingElement = defaultScrollingElement
) {
  if (__SERVER__ || !element) {
    return
  }

  disableCount = Math.max(0, disableCount - 1)

  if (disableCount > 0) {
    return
  }

  if (element.style.position !== 'fixed') {
    return
  }

  const previousScrollValue: number = getScrollTopBeforeDisable(element)

  element.style.position = ''
  element.style.top = ''
  element.style.width = ''

  element.scrollTop = previousScrollValue
}

export function getIsScrollDisabled() {
  return disableCount > 0
}

export function getScrollTopBeforeDisable(
  element: ScrollingElement = defaultScrollingElement
) {
  if (!element) {
    return 0
  }

  return parseInt(element.style.top, 10) * -1
}

export function getScrollTop(
  element: ScrollingElement = defaultScrollingElement
): number {
  if (__SERVER__ || typeof element === 'undefined') {
    return 0
  }

  return element.scrollTop
}

export function setScrollTop(
  scrollTop: number,
  element: ScrollingElement = defaultScrollingElement
) {
  if (__SERVER__ || typeof element === 'undefined') {
    return
  }

  setElementScroll(element, scrollTop)
}

export function setElementScroll(element: HTMLElement, y: number, x?: number) {
  if ('scrollTo' in element) {
    element.scrollTo(x ?? 0, y)
  } else {
    ;(element as HTMLElement).scrollTop = y
    if (x) {
      ;(element as HTMLElement).scrollLeft = x
    }
  }
}

export function animateScrollToWithMargin(
  targetElement: HTMLElement,
  scrollingElement = defaultScrollingElement
) {
  const scrollToMargin = Math.min(100, window.innerHeight * 0.1)
  const scrollToTarget = Math.min(
    targetElement.offsetTop - scrollToMargin,
    scrollingElement!.scrollHeight - window.innerHeight
  )

  return animateScroll(scrollingElement!, scrollToTarget)
}

export function animateScrollToCenter(
  targetElement: HTMLElement,
  scrollingElement = defaultScrollingElement
) {
  const elementTop = targetElement.offsetTop
  const elementHeight = targetElement.offsetHeight
  const halfWindowHeight = window.innerHeight / 2

  const scrollToTarget = elementTop - halfWindowHeight + elementHeight / 2

  return animateScroll(scrollingElement!, scrollToTarget)
}

import classNames from 'classnames'
import React from 'react'

import styles from './hero-heading.module.scss'


export default function HeroHeading({ 
  className,
  ...headingProps
}:JSX.IntrinsicElements['h1']) {
  return (
    <h1
      className={classNames(
        styles.heading,
         styles.noMaxWidth,
         styles.smallerInMobile,
        className
      )}
      {...headingProps}
    />
  )
}

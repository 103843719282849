import { easeInOutCubic } from './easings'

interface ITimingProps {
  duration: number
  easing?: (t: number) => number
}

export interface IAnimateEasingReturn {
  promise: Promise<any>
  stop: () => void
}

export default function animateEasing(
  { duration, easing = easeInOutCubic }: ITimingProps,
  tickCallback: (t: number) => void
): IAnimateEasingReturn {
  let _resolve: (didFinish: boolean) => void

  const promise = new Promise<boolean>((resolve) => {
    _resolve = resolve
  })

  let rafID: number
  const start = performance.now()

  function update() {
    const elapsed = performance.now() - start

    if (elapsed >= duration) {
      tickCallback(1)

      _resolve(true)
    } else {
      rafID = requestAnimationFrame(update)

      const t = Math.min(easing(elapsed / duration), 1)

      tickCallback(t)
    }
  }

  update()

  return {
    promise,
    stop: () => {
      cancelAnimationFrame(rafID)
      _resolve(false)
    },
  }
}

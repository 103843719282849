import animateEasing, { IAnimateEasingReturn } from './animateEasing'
import { easeInOutCubic } from './easings'

const { abs, max, min } = Math

const scrollCache =
  typeof WeakMap !== 'undefined'
    ? new WeakMap<HTMLElement, IAnimateEasingReturn>()
    : null

export default function animateScroll(
  element: HTMLElement,
  scrollTop?: number,
  scrollLeft?: number,
  duration?: number,
  easing = easeInOutCubic
) {
  const previousOngoingAnimation = scrollCache?.get(element)
  if (previousOngoingAnimation) {
    previousOngoingAnimation.stop()
  }

  const startScrollTop = element.scrollTop
  const startScrollLeft = element.scrollLeft

  const topDiff = (scrollTop ?? startScrollTop) - startScrollTop
  const leftDiff = (scrollLeft ?? startScrollLeft) - startScrollLeft

  if (!duration) {
    const maxDiff = max(abs(topDiff), abs(leftDiff))
    duration = getDurationFromScrollLength(maxDiff)
  }

  const animation = animateEasing({ duration, easing }, (t) => {
    element.scrollTop = startScrollTop + topDiff * t
    element.scrollLeft = startScrollLeft + leftDiff * t
  })

  const cancelEvents = ['touchmove', 'mousewheel', 'DOMMouseScroll']
  function onAnyCancelEvent() {
    animation.stop()
  }

  cancelEvents.forEach((eventName) => {
    element.addEventListener(eventName, onAnyCancelEvent)
  })

  animation.promise.then(() => {
    cancelEvents.forEach((eventName) => {
      element.removeEventListener(eventName, onAnyCancelEvent)
    })
  })

  scrollCache?.set(element, animation)

  return animation
}

export function getDurationFromScrollLength(scrollLength: number): number {
  const minMs = 300
  const maxMs = 1500 - minMs
  const scale = 0.25
  return minMs + min(maxMs, scrollLength * scale)
}

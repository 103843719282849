import { useEffect } from 'react'

import useAlwaysUpToDateRef from './utils/useAlwaysUpToDateRef'

export default function useOnWindowVisibilityChange(
  callback: (isVisible: boolean) => void
) {
  const callbackRef = useAlwaysUpToDateRef(callback)

  useEffect(() => {
    function onVisibilityChange() {
      callbackRef.current(!document.hidden)
    }

    document.addEventListener('visibilitychange', onVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange)
    }
  }, [])
}

import { BlobPoint } from './BlobPoint'
import Spring from './Spring'
import SpringValue from './SpringValue'
import Time from './Time'

const { PI, random } = Math

export default class Blob {
  maxRadius: number
  minRadius: number
  points: BlobPoint[]

  radiusSpring: SpringValue

  appearSpringConfig: Spring
  disappearSpringConfig: Spring

  constructor({
    radius,
    disappearRadius,
    pointCount,
    pointRadiusOffset,
    pointSpeedOffset,
    appearSpringConfig,
    disappearSpringConfig,
  }: {
    radius: number
    disappearRadius?: number
    pointCount: number
    pointRadiusOffset?: number
    pointSpeedOffset?: number
    appearSpringConfig?: Partial<Spring>
    disappearSpringConfig?: Partial<Spring>
  }) {
    this.maxRadius = radius
    this.minRadius = disappearRadius ?? 0

    this.points = new Array(pointCount)

    this.radiusSpring = new SpringValue()
    this.radiusSpring.targetValue = this.minRadius

    this.appearSpringConfig = appearSpringConfig
      ? Spring.fromSpring(appearSpringConfig)
      : new Spring(100, 10)

    this.disappearSpringConfig = disappearSpringConfig
      ? Spring.fromSpring(disappearSpringConfig)
      : new Spring(300, 30)

    for (let index = 0; index < pointCount; index++) {
      this.points[index] = new BlobPoint(
        ((PI * 2) / pointCount) * index,
        pointRadiusOffset ? random() * pointRadiusOffset : 0,
        pointSpeedOffset ? random() * pointSpeedOffset : 0
      )
    }
  }

  update(time: Time) {
    const points = this.points

    if (!this.radiusSpring.isStale) {
      this.radiusSpring.update(time.elapsedS)
    }

    const { length } = points
    for (let i = 0; i < length; i++) {
      points[i].update(this.radiusSpring.value, time.now)
    }
  }

  toSvgPathD() {
    const points = this.points
    const { length } = points
    const lastPoint = points[length - 1]

    let d = `M ${BlobPoint.centerAsStr(lastPoint, points[0])}`

    for (let i = 0; i < length; i++) {
      const point = points[i]
      const nextPoint = points[(i + 1) % length]
      d += ` Q ${point.x} ${point.y}, ${BlobPoint.centerAsStr(
        point,
        nextPoint
      )}`
    }

    return d
  }

  appear() {
    this.radiusSpring.targetValue = this.maxRadius
    this.radiusSpring.stiffness = this.appearSpringConfig.stiffness
    this.radiusSpring.dampness = this.appearSpringConfig.dampness
  }

  disappear() {
    this.radiusSpring.targetValue = this.minRadius
    this.radiusSpring.stiffness = this.disappearSpringConfig.stiffness
    this.radiusSpring.dampness = this.disappearSpringConfig.dampness
  }

  get isStale() {
    return this.radiusSpring.isStale
  }
}

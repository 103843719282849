import springStepper from '../animation/spring'

import Spring from './Spring'

export default class SpringValue extends Spring {
  value = 0
  targetValue = 0
  #velocity = 0

  update(stepDeltaTimeS: number) {
    if (this.value !== this.targetValue) {
      const [value, velocity] = springStepper(
        stepDeltaTimeS,
        this.value,
        this.#velocity,
        this.targetValue,
        this.stiffness,
        this.dampness,
        this.precision
      )
      this.value = value
      this.#velocity = velocity
    }
  }

  get isStale() {
    return this.value === this.targetValue
  }
}

const Colors = {
  White: '#fff',
  Sand: '#f6eee3',
  Yellow: '#c28e29',
  Orange: '#ff542f',
  RedDark: '#631c18',
  GreenLight: '#c2d0c9',
  GreenDark: '#3c4a2f',
  Brown: '#2f210c',
  MainBackground: '#faf7f3',
}

export default Colors

import { useState, useEffect } from 'react'

export interface WindowSize {
  width: number
  height: number
}

const __SERVER__ = typeof window === 'undefined'

function getWindowSize(): WindowSize {
  if (__SERVER__) {
    return {
      width: 1440,
      height: 800,
    }
  }

  return {
    width: window.innerWidth,
    height: window.innerHeight,
  }
}

export default function useWindowSize(debounceMs = 250) {
  const [size, setSize] = useState(getWindowSize())

  useEffect(() => {
    let debounceTimeoutId: ReturnType<typeof setTimeout>

    function onResize() {
      clearTimeout(debounceTimeoutId)
      debounceTimeoutId = setTimeout(() => setSize(getWindowSize()), debounceMs)
    }

    if (!__SERVER__) {
      window.addEventListener('resize', onResize)
    }

    return () => {
      if (!__SERVER__) {
        window.removeEventListener('resize', onResize)
      }
      clearTimeout(debounceTimeoutId)
    }
  }, [debounceMs])

  return size
}

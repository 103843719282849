import Cookies from 'js-cookie'

const isBrowser = () => typeof window !== 'undefined'

function track(eventname: string, trackObj: {}) {
  if (canTrack()) {
    window.analytics?.track(eventname, trackObj)
  }
}
function trackPage() {
  if (canTrack()) {
    window.analytics?.page()
  }
}
function identify(traits: Object) {
  if (canTrack()) {
    window.analytics?.identify(traits)
  }
}

function getCustomPreferences() {
  const trackingPrefs = Cookies.get('tracking-preferences')

  if (trackingPrefs && JSON.parse(trackingPrefs)) {
    return JSON.parse(trackingPrefs).custom
  } else return undefined
}
function getPreferences() {
  const trackingPrefs = Cookies.get('tracking-preferences')

  if (trackingPrefs && JSON.parse(trackingPrefs)) {
    return JSON.parse(trackingPrefs).destinations
  } else return undefined
}

function updateConsentPreferences(
  marketingAndAnalytics: boolean,
  advertising: boolean
) {
  return new Promise<void>((resolve, reject) => {
    if (canTrack()) {
      window.analytics
        ?.track('Consent Update', {
          'Ads Storage Consent State': advertising ? 'granted' : 'denied',
          'Ad User Data Consent State': advertising ? 'granted' : 'denied',
          'Ad Personalization Consent State': advertising
            ? 'granted'
            : 'denied',
          'Analytics Storage Consent State': marketingAndAnalytics
            ? 'granted'
            : 'denied',
        })
        .then(() => {
          resolve()
        })
        .catch((error: any) => {
          reject(error)
        })
    } else {
      reject(new Error('Tracking is not available'))
    }
  })
}

function getTraitsFromHubspotForm(
  hubspotFormFields: { [key: string]: string },
  identifyTraits?: Array<string>
) {
  let traits: { [key: string]: any } = {}
  let company: { [key: string]: string } = {}
  let address: { [key: string]: string } = {}
  identifyTraits?.forEach((hubSegKeyMapEntry: string) => {
    if (hubSegKeyMapEntry.includes('=')) {
      const keys = hubSegKeyMapEntry.split('=', 2)
      if ((keys.length = 2)) {
        const hubKey: string = keys[0]
        const segKey: string = keys[1]

        if (segKey.includes('.')) {
          const segKeys = segKey.split('.', 2)
          const segKeyOuter: string = segKeys[0]
          const segKeyNested: string = segKeys[1]
          if (segKeyOuter === 'company') {
            if (hubKey in hubspotFormFields) {
              company[segKeyNested] = hubspotFormFields[hubKey]
            }
          }
          if (segKeyOuter === 'address') {
            if (hubKey in hubspotFormFields) {
              address[segKeyNested] = hubspotFormFields[hubKey]
            }
          }
        } else if (hubKey in hubspotFormFields) {
          traits[segKey] = hubspotFormFields[hubKey]
        }
      }
    }
  })
  if (Object.keys(company).length > 0) {
    traits['company'] = company
  }
  if (Object.keys(address).length > 0) {
    traits['address'] = address
  }
  return traits
}

function canTrack() {
  if (!isBrowser) {
    return false
  } else {
    return true
  }
}

export const tracking = {
  track: track,
  page: trackPage,
  identify: identify,
  getPreferences: getPreferences,
  getCustomPreferences: getCustomPreferences,
  canTrack: canTrack,
  isBrowser: isBrowser,
  getTraitsFromHubspotForm: getTraitsFromHubspotForm,
  updateConsentPreferences: updateConsentPreferences,
}

const { abs } = Math

export interface Spring {
  stiffness: number
  dampness: number
  precision: number
}

let reusedTuple: [number, number] = [0, 0]
export default function springStepper(
  secondPerFrame: number,
  currentValue: number,
  currentVelocity: number,
  targetValue: number,
  stiffness: number,
  dampness: number,
  precision: number
): [number, number] {
  // Spring stiffness, in kg / s^2

  // for animations, destX is really spring length (spring at rest). initial
  // position is considered as the stretched/compressed position of a spring
  const Fspring = -stiffness * (currentValue - targetValue)

  // Damping, in kg / s
  const Fdamper = -dampness * currentVelocity

  // usually we put mass here, but for animation purposes, specifying mass is a
  // bit redundant. you could simply adjust k and b accordingly
  // let a = (Fspring + Fdamper) / mass;
  const a = Fspring + Fdamper

  const newVelocity = currentVelocity + a * secondPerFrame
  const newValue = currentValue + newVelocity * secondPerFrame

  if (abs(newVelocity) < precision && abs(newValue - targetValue) < precision) {
    reusedTuple[0] = targetValue
    reusedTuple[1] = 0
    return reusedTuple
  }

  reusedTuple[0] = newValue
  reusedTuple[1] = newVelocity
  return reusedTuple
}

import { tracking } from './tracking'

export type LinkTrackProps = {
  anchor_text: string | undefined | null
  destination?: 'internal' | 'external'
  position: 'body' | 'footer' | 'navigation' | undefined
  url: string | undefined | null
}

export function getSegmentLinkTrackingdData({
  anchor_text,
  destination,
  position,
  url,
}: LinkTrackProps) {
  const trackObj = {
    anchor_text: anchor_text,
    category: 'Referral',
    destination: destination,
    position: position,
    url: url,
  }
  return tracking.track('Link Clicked', trackObj)
}
//------

// export type ButtonTrackProps = {
//   color: string | undefined | null
//   cta?: string
//   type?: string
// }

// export function getSegmentButtonTrackingdData({
//   color,
//   cta,
//   type,
// }: ButtonTrackProps) {
//   const trackObj = {
//     category: 'UX',
//     color: color,
//     cta: cta,
//     type: type,
//   }
//   return tracking.track('Button Clicked', trackObj)
// }

import classNames from 'classnames'
import React from 'react'

import Colors from '../../../constants/Colors'

import styles from './svg-logo.module.scss'

interface SvgLogoProps {
  color?: string | null

  isCollapsed?: boolean
}

export default function SvgLogo({
  color,

  isCollapsed = false,

  className,

  ...svgProps
}: SvgLogoProps & Omit<JSX.IntrinsicElements['svg'], 'color'>) {
  const renderColor = color ?? Colors.Brown

  return (
    <svg
      className={classNames(
        styles.container,
        isCollapsed && styles.isCollapsed,
        className
      )}
      width="421"
      height="121"
      viewBox="0 0 421 121"
      fill={renderColor}
      {...svgProps}
    >
      <title>Kruso Logo</title>

      <path d="M80 112H58L28 74l30-40h22L50 74l30 38zM18 0H0v112h18V0z" />
      <path d="M18.37 0H0V112.38H18.37V0Z" />

      <g>
        <path
          className={styles.expandedItem}
          d="M149 51V33c-15 0-22 2-29 7l-2 2v-8H99v79h19V73c0-14 5-22 31-22z"
        />

        <path
          className={styles.expandedItem}
          d="M204 115c24 0 37-12 37-35V34h-19v41c0 14-3 22-18 22s-18-8-18-22V34h-19v46c0 23 13 35 37 35z"
        />

        <path
          className={styles.expandedItem}
          d="M293 66c-8-3-14-5-14-10 0-2 0-6 9-6 6 0 13 2 18 5l10-13c-10-8-24-9-28-9-17 0-27 10-27 25 0 16 14 20 26 24 8 2 15 4 15 9 0 2-1 7-12 7-8 0-15-2-22-7l-9 14c7 5 16 10 30 10 19 0 30-9 30-24 0-17-15-22-26-25z"
        />

        <path
          className={styles.expandedItem}
          d="M373 33c-23 0-42 18-42 41s19 41 42 41 42-18 42-41-18-41-42-41zm0 64a23 23 0 110-46c13 0 24 10 24 23a24 24 0 01-24 23z"
        />
      </g>

      <path
        className={styles.collapsedItem}
        d="M91 113h22l30-39-30-40H91l30 40-30 39z"
      />
    </svg>
  )
}

import { useCallback, useRef, useState } from 'react'

import Time from '../utils/blob-classes/Time'

import useOnMount from './useOnMount'
import useOnWindowVisibilityChange from './useOnWindowVisibilityChange'
import useAlwaysUpToDateRef from './utils/useAlwaysUpToDateRef'

export default function useAnimationLoop(stepCallback: (time: Time) => void) {
  const [time] = useState(() => new Time())

  const rafIdRef = useRef(-1)
  const shouldResumeOnWindowFocusRef = useRef(false)

  const stepCallbackRef = useAlwaysUpToDateRef(stepCallback)

  useOnMount(() => {
    return () => {
      cancelAnimationFrame(rafIdRef.current)
    }
  })

  useOnWindowVisibilityChange((isVisible) => {
    if (isVisible) {
      if (shouldResumeOnWindowFocusRef.current) {
        start()
      }
    } else {
      const shouldResumeOnWindowFocus = rafIdRef.current !== -1
      stop()
      shouldResumeOnWindowFocusRef.current = shouldResumeOnWindowFocus
    }
  })

  const start = useCallback(function startAnimationLoop() {
    if (rafIdRef.current === -1) {
      time.reset()

      function nextStep() {
        rafIdRef.current = requestAnimationFrame(nextStep)

        time.update()
        stepCallbackRef.current(time)
      }

      rafIdRef.current = requestAnimationFrame(nextStep)
    }
  }, [])

  const stop = useCallback(function stopAnimationLoop() {
    cancelAnimationFrame(rafIdRef.current)
    rafIdRef.current = -1
    shouldResumeOnWindowFocusRef.current = false
  }, [])

  return { start, stop }
}
